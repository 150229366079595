<template>
  <div class="feeTable">
    <div class="entry-content" v-for="(data, index) in tableData" :key="index">
      <h3>{{ index + 1 + "、" + data.cate_name }}</h3>
      <table width="100%" border="1" cellpadding="0" align="center">
        <thead>
          <tr>
            <th width="140">{{ $t("business.transCost.tableHead[0]") }}</th>
            <th>{{ $t("business.transCost.tableHead[1]") }}</th>
            <th width="278">{{ $t("business.transCost.tableHead[2]") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in data.list" :key="index + idx">
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.explain }}
            </td>
            <td>
              {{ item.owner }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      api.common
        .qksFeeList()
        .then((res) => {
          const list = this.splitKind(res);
          console.log(list);
          this.tableData = list;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    splitKind(arr) {
      let dataArr = [];
      arr.map((mapItem) => {
        if (dataArr.length == 0) {
          dataArr.push({
            cate_id: mapItem.cate_id,
            cate_name: mapItem.cate_name,
            list: [mapItem],
          });
        } else {
          let res = dataArr.some((item) => {
            //判断相同日期，有就添加到当前项
            if (item.cate_id == mapItem.cate_id) {
              item.list.push(mapItem);
              return true;
            }
          });
          if (!res) {
            //如果没找相同日期添加一个新对象
            dataArr.push({
              cate_id: mapItem.cate_id,
              cate_name: mapItem.cate_name,
              list: [mapItem],
            });
          }
        }
      });
      return dataArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.feeTable {
  background-color: var(--whiteColor);
  color: var(--blackColor);
  padding-bottom: 80px;
  .entry-content {
    padding-top: 76px;
  }
  .entry-content h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .entry-content thead {
    background-color: var(--theadBgColor);
    th {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .entry-content tr {
    height: 50px;
  }
  .entry-content td,
  .entry-content th {
    word-break: break-all;
    border: 1px solid var(--iconGray);
    text-align: center;
    vertical-align: middle;
  }
}
</style>
